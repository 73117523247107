@import 'themes/common.scss';

.button_big__wrapper {
  padding: rem(12) rem(24);
  @include text-16-600;
  border: none;
  background-color: transparent;
  border-radius: rem(12);
  box-sizing: border-box;
  cursor: pointer;

  &--primary {
    color: var(--color-text-default-invert);
    background-color: var(--color-button-primary-default);

    &:hover {
      background-color: var(--color-button-primary-hover);
    }

    &:active {
      background-color: var(--color-button-primary-pressed);
    }

    &:disabled {
      background-color: var(--color-button-primary-disabled);
    }
  }

  &--secondary {
    color: var(--color-text-default-primary);

    &:hover {
      background-color: var(--color-button-secondary-hover);
    }

    &:active {
      background-color: var(--color-button-secondary-pressed);
    }

    &:disabled {
      color: var(--color-text-default-disabled);
      background-color: transparent;
    }
  }

  &--destructive {
    color: var(--color-text-default-invert);
    background-color: var(--color-button-destructive-primary-default);

    &:hover {
      background-color: var(--color-button-destructive-primary-hover);
    }

    &:active {
      background-color: var(--color-button-destructive-primary-pressed);
    }

    &:disabled {
      background-color: var(--color-button-destructive-primary-disabled);
    }
  }
}