@import 'themes/common.scss';
.custom-input-wrapper {
    align-items: center;
    position: relative;
    height: rem(70);
    margin-top: rem(3);

    .custom-tooltip {
        transform: translateX(rem(2));
        max-width: rem(500); 
        white-space: normal; 
        word-wrap: break-word;
        z-index: 100;
        border-radius: rem(6);
        font-size: em(12);
        white-space: pre-line;
    }
}

.form-group {
  position: relative;
}

.form-input {
  width: 100%;
  height: rem(46);
  font-size: em(12);
  border: rem(1) solid var(--color-border-default-secondary);
  border-radius: rem(8);
  padding-left: rem(16);
  box-sizing: border-box;
  outline: 0;
}

.form-label {
  position: absolute;
  top: 50%;
  left: rem(10);
  transform: translateY(-50%);
  font-size: em(12);
  color: var(--color-text-default-secondary);
  transition: all 0.3s ease;
  pointer-events: none;
  cursor: pointer;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-input:focus {
  border-color: var(--color-border-brand-purple);
}

.form-input:focus + .form-label {
  color: var(--color-text-status-purple);
}

.form-label.active {
  top: 0;
  font-size: em(10);
  color: #495057;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: rem(2);
  cursor: pointer;
  display: block;
  span {
    background: var(--color-surface-default-secondary);
    padding: rem(5);
    cursor: pointer;
  }
}

.hiden-hover {
  position: absolute;
  height: rem(10);
  background: transparent;
  cursor: pointer;
  margin-left: rem(15);
  top: rem(-5);
}

.errorBlock {
  background: url(./img/error.svg) no-repeat;
  color: var(--color-icon-message-default-negative);
  padding-left: rem(20);
  font-size: em(12);
  margin-top: rem(4);
  height: rem(20);
  line-height: rem(16);
  margin-left: rem(8);
}

.warningBlock {
  background: url(./img/info_f.svg) no-repeat;
  background-size: rem(17);
  color: #495057;
  padding-left: rem(20);
  font-size: em(12);
  margin-top: rem(4);
  height: rem(20);
  line-height: rem(16);
  margin-left: rem(8);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: rem(100);
  box-sizing: border-box;
}
