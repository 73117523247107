@use 'sass:math';

$browser-context: 16; // Default

@function em($pixels, $context: $browser-context) {
  @return math.div($pixels, $context) * 1em;
}

@function rem($pixels, $context: $browser-context) {
  @return math.div($pixels, $context) * 1rem;
}

$hover_transition: 0.1s;
$media_transition: 0.2s;
$small_widget_width_md: rem(376);
$small_widget_width_lg: 25%;
$small_widget_margin_right: rem(30);
$page-vertical-padding: rem(24);
$header-height: rem(72);
$alerts_widget_width_sm: rem(389);
$alerts_widget_width_md: rem(414);
$alerts_widget_width_lg: rem(438);
$alerts_widget_left_margin: rem(24);
$large_widget_width_sm: calc(100% - $alerts_widget_width_sm - $alerts_widget_left_margin);
$large_widget_width_md: calc(100% - $alerts_widget_width_md - $alerts_widget_left_margin);
$large_widget_width_lg: calc(100% - $alerts_widget_width_lg - $alerts_widget_left_margin);
$sizesHeight: (
  'sm': 720px
);
$sizes: (
  'sm': 1280px,
  'md': 1620px,
  'lg': 1920px
);
$box_shadow_1: 0 rem(9) rem(18.7) rem(-4) rgba(69, 55, 81, 0.15);

@mixin media($minmax, $media) {
  @each $size, $resolution in $sizes {
    @if $media ==$size {
      @media only screen and (#{$minmax}-width: $resolution) {
        @content;
      }
    }
  }
}

@mixin mediaHeight($minmax, $media) {
  @each $size, $resolution in $sizesHeight {
    @if $media ==$size {
      @media only screen and (#{$minmax}-height: $resolution) {
        @content;
      }
    }
  }
}

@mixin manrope-font($weight: 400) {
  font-family: 'Manrope', sans-serif;
  font-weight: $weight;
}

@mixin text-8-700 {
  font-size: em(8);
  line-height: normal;
  @include manrope-font(700);
}

@mixin text-10-700 {
  font-size: em(10);
  line-height: normal;
  @include manrope-font(700);
}

@mixin text-12-400 {
  font-size: em(12);
  line-height: normal;
  @include manrope-font(400);
}

@mixin text-12-700 {
  font-size: em(12);
  line-height: normal;
  @include manrope-font(700);
}

@mixin text-14-400 {
  font-size: em(14);
  line-height: normal;
  @include manrope-font(400);
}

@mixin text-14-600 {
  font-size: em(14);
  line-height: normal;
  @include manrope-font(600);
}

@mixin text-14-700 {
  font-size: em(14);
  line-height: normal;
  @include manrope-font(700);
}

@mixin text-16-400 {
  font-size: em(16);
  line-height: normal;
  @include manrope-font(400);
}

@mixin text-16-600 {
  font-size: em(16);
  line-height: normal;
  @include manrope-font(600);
}

@mixin text-16-700 {
  font-size: em(16);
  line-height: normal;
  @include manrope-font(700);
}

@mixin text-20-700 {
  font-size: em(20);
  line-height: normal;
  @include manrope-font(700);
}

@mixin text-24-700 {
  font-size: em(24);
  line-height: normal;
  @include manrope-font(700);
}

@mixin text-28-700 {
  font-size: em(28);
  line-height: normal;
  @include manrope-font(700);
}

@mixin text-32-700 {
  font-size: em(32);
  line-height: normal;
  @include manrope-font(700);
}

@mixin text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin scroll() {
  &::-webkit-scrollbar {
    width: rem(4);
    /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    margin: rem(5) 0;
    border-radius: rem(2);
    background: transparent;
    /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    border-radius: rem(2);
    background: var(--color-border-default-secondary);
    /* creates padding around scroll thumb */
  }
}

@mixin tooltip_visible {
  visibility: visible;
  opacity: 1;
}

@mixin fadeIn($duration: 0.5s) {
  animation: fadeIn $duration forwards;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@mixin fadeOut($duration: 0.5s) {
  animation: fadeOut $duration forwards;
  pointer-events: none;
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
