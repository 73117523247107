@import 'themes/common.scss';

.login__page_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--color-surface-default-primary);

  .login__form_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem(30);
    box-sizing: border-box;
    width: rem(480);
    padding: 48px;
    background: var(--color-surface-default-secondary);
    border-radius:rem(24);
  }

  .login__form_logo {
    width: rem(152);
    height: rem(50);
    background-image: url(assets/AppLogo.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .login__form_welcome_title {
    font-size: em(32);
    font-weight: 700;
    margin: rem(40) 0;
  }

  .login__form_input {
    width: 100%;
  }

  .login__form_button_login_wrapper {
    width: 100%;
    button {
      width: 100%;
    }
  }

  .login__form_sep_line {
    width: 100%;
    height: rem(1);
    background: var(--color-border-default-secondary);
    margin: rem(27) 0;
    position: relative;

    &:after {
      content: "or";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -56%);
      background-color: white;
      padding: rem(3) rem(10);
      color: var(--color-text-default-secondary)
    }

  }

  .login__form_cognito_btn {
    background: none;
    border: 0;
    cursor: pointer;
    background: url(./assets/azure_logo.svg) no-repeat;
    padding-left: rem(25);
    @include text-16-600;
    background-position-y: rem(3);
  }

  .login__form_error {
    height: rem(22);
    margin: 0 0 rem(16);
    color: var(--color-text-message-negative);
    @include text-16-400;
  }

  .login__form_btn {
    color: var(--color-button-primary-default);
    @include text-16-600;
  }
}