@import './variables.scss';

:root {
  --color-surface-default-primary: #{$grey-50};
  --color-surface-default-secondary: #{$white-100};
  --color-surface-default-invert: #{$grey-800};
  --color-surface-default-tertiary: #{$grey-200};

  --color-surface-message-positive: #{$green-50};
  --color-surface-message-warning: #{$orange-50};
  --color-surface-message-negative: #{$red-50};

  --color-surface-status-blue: #{$blue-50};
  --color-surface-status-purple: #{$purple-50};
  --color-surface-status-purple_dark: #{$purple-100};
  --color-surface-status-pink: #{$pink-50};
  --color-surface-status-yellow: #{$yellow-50};
  --color-surface-status-green: #{$green-100};
  --color-surface-status-grey: #{$grey-200};

  --color-text-default-primary: #{$grey-900};
  --color-text-default-secondary: #{$grey-700};
  --color-text-default-disabled: #{$grey-300};
  --color-text-default-invert: #{$white-100};

  --color-text-brand-purple: #{$purple-600};

  --color-text-message-positive: #{$green-500};
  --color-text-message-warning: #{$orange-500};
  --color-text-message-negative: #{$red-500};

  --color-text-status-blue: #{$blue-500};
  --color-text-status-purple: #{$purple-500};
  --color-text-status-pink: #{$pink-500};
  --color-text-status-yellow: #{$yellow-500};

  --color-icon-brand-purple: #{$purple-600};

  --color-icon-default-primary: #{$grey-900};
  --color-icon-default-secondary: #{$grey-700};
  --color-icon-default-tertiary: #{$grey-500};
  --color-icon-default-disabled: #{$grey-300};
  --color-icon-default-invert: #{$white-100};

  --color-icon-message-default-positive: #{$green-500};
  --color-icon-message-default-warning: #{$orange-500};
  --color-icon-message-default-negative: #{$red-500};

  --color-icon-message-invert-positive: #{$green-400};
  --color-icon-message-invert-warning: #{$orange-400};
  --color-icon-message-invert-negative: #{$red-400};

  --color-icon-status-blue: #{$blue-500};
  --color-icon-status-purple: #{$purple-600};
  --color-icon-status-pink: #{$pink-500};
  --color-icon-status-yellow: #{$yellow-500};

  --color-border-default-primary: #{$grey-900};
  --color-border-default-secondary: #{$grey-400};

  --color-border-brand-purple: #{$purple-600};

  --color-border-message-primary-positive: #{$green-500};
  --color-border-message-primary-warning: #{$orange-500};
  --color-border-message-primary-negative: #{$red-500};

  --color-border-message-secondary-positive: #{$green-100};
  --color-border-message-secondary-warning: #{$orange-100};
  --color-border-message-secondary-negative: #{$red-100};

  --color-button-primary-default: #{$purple-600};
  --color-button-primary-hover: #{$purple-500};
  --color-button-primary-pressed: #{$purple-700};
  --color-button-primary-disabled: #{$grey-300};

  --color-button-secondary-default: #{$grey-100};
  --color-button-secondary-hover: #{$purple-80};
  --color-button-secondary-pressed: #{$purple-100};
  --color-button-secondary-disabled: #{$grey-100};

  --color-button-destructive-primary-default: #{$red-500};
  --color-button-destructive-primary-hover: #{$red-400};
  --color-button-destructive-primary-pressed: #{$red-600};
  --color-button-destructive-primary-disabled: #{$grey-100};

  --color-button-destructive-secondary-default: #{$red-50};
  --color-button-destructive-secondary-hover: #{$red-100};
  --color-button-destructive-secondary-pressed: #{$red-200};
  --color-button-destructive-secondary-disabled: #{$grey-100};

  --color-elements-empty-hover: #{$purple-80};
  --color-elements-empty-pressed: #{$purple-100};

  --color-elements-filled-default: #{$purple-50};
  --color-elements-filled-hover: #{$purple-80};
  --color-elements-filled-pressed: #{$purple-100};
  --color-elements-filled-disabled: #{$grey-100};

  --color-orange: #{$orange-500};
  --color-yellow: #{$yellow-600};
  --color-blue: #{$blue-500};
  --color-purple: #{$purple-600};
  --color-green: #{$green-500};
  --color-pink: #{$pink-500};

  --color-avatar-orange: #{$orange-500};
  --color-avatar-purple: #{$purple-600};

  --color-grey-200: #{$grey-200};
  --color-grey-900: #{$grey-900};
}
